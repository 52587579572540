import React, { useEffect } from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, Checkbox, FormControlLabel, Grid, TextField, Theme, createStyles, makeStyles } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { FormApi, ValidationErrors } from 'final-form';
import { checkIfIntialValuesIsEqualToValues } from '../../helpers/commonHelper';
import DialogBox from '../../Dashboard/Component/DialogBox';
import { FieldType } from '../selectors/CustomFieldTypeSelector';
import { CurrencyInput2 } from '../../components/CurrencyInput';
import { KeyboardDateTimePicker } from '@material-ui/pickers/DateTimePicker/DateTimePicker';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker/DatePicker';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { DropzoneArea } from 'material-ui-dropzone';
import { DocumentFile } from '../../types/DocumentFile';
import { Autocomplete } from '@material-ui/lab';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { useQuery } from 'react-apollo';
import { RvLoader } from '../../components/Loader';
import { CustomField, CustomFieldListData, CustomFieldListParams, CustomFieldListQuery } from '../tabs/customFieldGroups/CustomFieldGroupSelectedTab';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            textTransform: 'capitalize',
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        button: {
            margin: theme.spacing(1),
            minWidth: '83px',
        },
        textField: {
            width: '100%',
        },
        dropZoneFont: {
            '& .MuiDropzoneArea-text': {
                fontSize: '1em',
            }
        },
        dropZone: {
            minHeight: 'fit-content !important',
            '& .MuiDropzoneArea-root': {
                minHeight: 'fit-content !important'
            }
        },
    }),
);

interface CustomFieldPreviewDialogProps extends Pick<DialogProps, 'open' | 'onClose' | 'maxWidth' | 'fullWidth'> {
    data?: CustomField[];
    areaOfLawGuid?: string;
    customFieldGroupGuid?: string;
    locationGuid?: string;
}

interface CustomFieldForm {
    isFormSubmit: boolean;
    [key: string]: any;
}

export const CustomFieldPreviewDialog: React.FC<CustomFieldPreviewDialogProps> = (props) => {
    const classes = useStyles();

    const [showDiscard, setShowDiscard] = React.useState<boolean>(false);

    const [customFields, setCustomFields] = React.useState<CustomField[]>(props.data || []);

    const customFieldListQuery = useQuery<CustomFieldListData, CustomFieldListParams>(CustomFieldListQuery, {
        variables: {
            areaOfLawGuid: props.areaOfLawGuid || null,
            customFieldGroupGuid: props.customFieldGroupGuid || null,
            locationGuid: props.locationGuid || null
        },
        skip: props.data !== undefined
    });

    useEffect(() => {

        if (customFieldListQuery.data && customFieldListQuery.data.crm && customFieldListQuery.data.crm.customField) {
            setCustomFields(customFieldListQuery.data.crm.customField);
        }

    }, [customFieldListQuery.data, customFieldListQuery.loading, customFieldListQuery.error]);
    
    const initialValues: CustomFieldForm = {
        isFormSubmit: false,
    };

    const handleClose = (form: FormApi<CustomFieldForm>, values: CustomFieldForm) => () => {

        const areValuesEqual = checkIfIntialValuesIsEqualToValues(initialValues, values);

        if (!areValuesEqual) {
            setShowDiscard(true);
        } else {
            if (props.onClose) {
                props.onClose({}, 'backdropClick');
            }
        }
    };

    const onDiscardChanges = (agree: boolean) => {
        if (agree) {
            if (props.onClose) {
                props.onClose({}, 'backdropClick');
            }
        }
        setShowDiscard(false);
    };

    const onSubmit = (values: CustomFieldForm, form: FormApi<CustomFieldForm, CustomFieldForm>) => {
        // tslint:disable-next-line: no-console
        console.log('onSubmit', values);

        
    };

    const onValdiate = (values: CustomFieldForm) => {
        return undefined;
    };

    const onRequired = (value: CustomFieldForm) => (value ? undefined : 'Required');

    const onFieldRequiredError = (errors: ValidationErrors, form: FormApi<CustomFieldForm>, values: CustomFieldForm) => {
        // code here
        console.log('errors', errors);
    };

    const getField = (field: CustomField, index: number) => {

        if (field.customFieldType === FieldType.Integer) { 
            return (
                <Field key={index} name={field.name} subscription={{ touched: true, error: true, value: true }} validate={field.isMandatory ? onRequired : undefined}>
                    {({ input, meta }) => (
                        <TextField
                            {...input}
                            className={classes.textField}
                            error={meta.error && meta.touched}
                            label={field.name}
                            helperText={meta.error && meta.touched ? meta.error : ''}
                            required={field.isMandatory}
                            type="number"
                        />
                    )}
                </Field>
            );
        }

        if (field.customFieldType === FieldType['Multi Line']) { 
            return (
                <Field key={index} name={field.name} subscription={{ touched: true, error: true, value: true }} validate={field.isMandatory ? onRequired : undefined}>
                    {({ input, meta }) => (
                        <TextField
                            {...input}
                            className={classes.textField}
                            error={meta.error && meta.touched}
                            label={field.name}
                            helperText={meta.error && meta.touched ? meta.error : ''}
                            required={field.isMandatory}
                            multiline={true}
                        />
                    )}
                </Field>
            );
        }

        if (field.customFieldType === FieldType.Number) { 
            return (
                <Field key={index} name={field.name} subscription={{ touched: true, error: true, value: true }} validate={field.isMandatory ? onRequired : undefined}>
                    {({ input, meta }) => (
                        <TextField
                            {...input}
                            className={classes.textField}
                            error={meta.error && meta.touched}
                            label={field.name}
                            helperText={meta.error && meta.touched ? meta.error : ''}
                            required={field.isMandatory}
                            type="number"
                        />
                    )}
                </Field>
            );
        }

        if (field.customFieldType === FieldType.Currency) {
            return (
                <Field key={index} name={field.name} subscription={{ touched: true, error: true, value: true }} validate={field.isMandatory ? onRequired : undefined}>
                    {(fieldProps) => (
                        <CurrencyInput2 
                            {...fieldProps}
                            className={classes.textField}
                            helperText={fieldProps.meta.error && fieldProps.meta.touched ? fieldProps.meta.error : ''}
                            fixedDecimalScale={true}
                            label={field.name}
                            prefix={'$'}
                            required={field.isMandatory}
                            // InputLabelProps={{ shrink: true }}
                        />
                    )}
                </Field>
            );
        }

        if (field.customFieldType === FieldType.Date) {
            return (
                <Field key={index} name={field.name} subscription={{ touched: true, error: true, value: true }} validate={field.isMandatory ? onRequired : undefined}>
                    {({ input, meta }) => (
                        <div>
                            <KeyboardDatePicker
                                {...input}
                                className={classes.textField}
                                label={field.name}
                                format="DD/MM/YYYY"
                                placeholder="dd/mm/yyyy"
                                required={field.isMandatory}
                                animateYearScrolling={true}
                                // tslint:disable-next-line: max-line-length
                                autoOk={true}
                                allowKeyboardControl={true}
                                variant={'inline'}
                                error={meta.error && meta.touched}
                                helperText={
                                    meta.error && meta.touched
                                        ? meta.error // 'Follow Up Due date is required'
                                        : ''
                                }
                                inputProps={{ autocomplete: 'off' }}
                                InputLabelProps={{
                                    shrink: input.value ? true : false,
                                }}
                            />
                        </div>
                    )}
                </Field>
            );
        }

        if (field.customFieldType === FieldType['Date Time']) {
            return (
                <Field key={index} name={field.name} subscription={{ touched: true, error: true, value: true }} validate={field.isMandatory ? onRequired : undefined}>
                    {({ input, meta }) => (
                        <div>
                            <KeyboardDateTimePicker
                                {...input}
                                className={classes.textField}
                                label={field.name}
                                format="DD/MM/YYYY hh:mm a"
                                placeholder="dd/mm/yyyy hh:mm a"
                                required={field.isMandatory}
                                animateYearScrolling={true}
                                // tslint:disable-next-line: max-line-length
                                autoOk={true}
                                allowKeyboardControl={true}
                                variant={'inline'}
                                error={meta.error && meta.touched}
                                helperText={
                                    meta.error && meta.touched
                                        ? meta.error // 'Follow Up Due date is required'
                                        : ''
                                }
                                inputProps={{ autocomplete: 'off' }}
                                InputLabelProps={{
                                    shrink: input.value ? true : false,
                                }}
                            />
                        </div>
                    )}
                </Field>
            );
        }

        if (field.customFieldType === FieldType.Boolean) {
            return (
                <Field key={index} name={field.name} subscription={{ touched: true, error: true, value: true }} validate={field.isMandatory ? onRequired : undefined}>                                           
                    {({ input, meta }) => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    {...input}
                                    checkedIcon={<CheckBoxIcon color="primary" />}
                                />
                            }
                            label={field.name}
                        />
                    )}
                </Field>
            );
        }

        if (field.customFieldType === FieldType['File Upload']) {
            return (
                <Field key={index} name={field.name} subscription={{ touched: true, error: true, value: true }} validate={field.isMandatory ? onRequired : undefined}>                                           
                    {({ input, meta }) => (
                        <DropzoneArea
                            {...input}
                            inputProps={{
                                className: classes.dropZone
                            }}
                            key={field.guid}
                            maxFileSize={15000000}
                            // acceptedFiles={['.msg', '.jpg', '.jpeg', '.png', '.docx', '.zip']}
                            showFileNames={true}
                            useChipsForPreview={true}
                            filesLimit={10}
                            dropzoneText="To add a file, drag and drop or click in this box.
                            Click the 'X' on a file to delete.
                            Click the filename to replace it.
                            View a file using the links above.
                            These links will become available once the enquiry is saved."
                            dropzoneClass={classes.dropZoneFont}
                            // tslint:disable-next-line: no-shadowed-variable
                            onChange={(files: File[]) => {
                                let existingFiles: DocumentFile[] = [...input.value as any];
                                const newFiles = files.filter((file: File) => {
                                    return !existingFiles.some((item: DocumentFile) => {
                                        return item.name === file.name && item.status !== 2;
                                    });
                                });
                                if (newFiles && newFiles.length > 0) {
                                    newFiles.forEach((file: File) => {
                                        const documentFile: DocumentFile = {
                                            guidID: null,
                                            file: file,
                                            status: 1,
                                            name: file.name,
                                            url: ''
                                        };
                                        existingFiles.push(documentFile);
                                    });
                                    input.onChange(existingFiles);
                                }
                            }}
                            onDelete={(file: File) => {
                                let existingFiles: DocumentFile[] = [...input.value as any];
                                // eslint-disable-next-line array-callback-return
                                existingFiles.map((item: DocumentFile) => {
                                    if (file.name === item.name) {
                                        item.file = file;
                                        item.status = 2; // deleted
                                    }
                                });
                                // tslint:disable-next-line: max-line-length
                                const filteredFiles = existingFiles.filter((item: DocumentFile) => {
                                    return item.status !== 2 || item.guidID !== null;
                                });
                                input.onChange(filteredFiles);
                            }}
                            // tslint:disable-next-line
                            onDrop={(files: File[], event: any) => {
                                // let existingFiles: DocumentFile[] = [...input.value];
                                // tslint:disable-next-line: max-line-length
                                // const existingFile = existingFiles.find((item: DocumentFile) => item.name === file)
                                // tslint:disable-next-line:no-console
                                console.log(event);
                            }}
                            onDropRejected={() => {
                                // return input.errorNotice('Maximum file upload size is 15MB');
                            }}
                            initialFiles={input.value ? input.value.map((file: { url: string; }) => file.url) : []}
                        />
                    )}
                </Field>
            );
        }

        if (field.customFieldType === FieldType['Lookup List']) {

            const options: IAutoCompleteItem[] = field.customFieldLookupItems.map((item) => {
                return { 
                    ...item,
                    value: item.guidId, 
                    label: item.description 
                };
            }).sort((a, b) => a.sequence - b.sequence);

            return (
                <Field key={index} name={field.name} subscription={{ touched: true, error: true, value: true }} validate={field.isMandatory ? onRequired : undefined}>
                    {({ input, meta }) => (
                        <Autocomplete 
                            {...input}
                            value={input.value as any}
                            options={options}
                            getOptionLabel={(option) => option.label}
                            onChange={(_, value) => {
                                input.onChange(value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className={classes.textField}
                                    error={meta.error && meta.touched}
                                    label={field.name}
                                    helperText={meta.error && meta.touched ? meta.error : ''}
                                    required={field.isMandatory}
                                />
                            )}
                        />
                    )}
                </Field>
            );
        }

        return (
            <Field key={index} name={field.name} subscription={{ touched: true, error: true, value: true }} validate={field.isMandatory ? onRequired : undefined}>
                {({ input, meta }) => (
                    <TextField
                        {...input}
                        className={classes.textField}
                        error={meta.error && meta.touched}
                        label={field.name}
                        helperText={meta.error && meta.touched ? meta.error : ''}
                        required={field.isMandatory}
                    />
                )}
            </Field>
        );
        
    };
    
    const controlButtonComponent = (form: FormApi<CustomFieldForm>, submitting: boolean, pristine: boolean, values: CustomFieldForm) => {
        return (
            <div className={classes.buttonContainer}>
                {/* <RenderCount /> */}
                {/* <div>
                    <Button
                        color="primary"
                        className={classes.button}
                        disabled={submitting || pristine}
                        onClick={() => {
                            form.change('isFormSubmit', true);
                            form.submit();
                        }}
                    >
                        Save
                    </Button>
                </div> */}
                <div>
                    <Button color="primary" type="button" className={classes.button} onClick={handleClose(form, values)}>
                        Cancel
                    </Button>
                </div>
            </div>
        );
    };
    
    const standarFields = (form: FormApi<CustomFieldForm>, submitting: boolean, pristine: boolean, values: CustomFieldForm) => {
        
        if (customFields.length === 0) {
            return (
                <div>
                    No Custom Fields Found
                </div>
            );
        }

        return (
            <Grid container={true} spacing={1}>

                {customFields.filter((field) => field.isAtive).map((field, index) => (
                    <Grid item={true} xs={12} sm={12} md={12} lg={12} key={index}>
                        {getField(field, index)}
                    </Grid>
                ))}
            </Grid>
        );
    };
    
    if (customFieldListQuery.loading) {
        return (
            <Dialog 
                {...props}
            >
                <DialogTitle className={classes.header}>Custom Field Group Preview</DialogTitle>
                <DialogContent>
                    <RvLoader />
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <>
            <DialogBox
                title="Custom Field Group"
                // tslint:disable-next-line:max-line-length
                content={`Are you sure you want to close the form?`}
                show={showDiscard}
                isAgree={onDiscardChanges}
                disAgreeLabel={'No'}
                agreeLabel={'Yes'}
            />
            <Form
                onSubmit={onSubmit}
                // mutators={{ ...arrayMutators }}
                initialValues={initialValues}
                validate={onValdiate}
                keepDirtyOnReinitialize={true}
                initialValuesEqual={() => true}
                // debug={(values) => {
                //     // tslint:disable-next-line: no-console
                //     console.log('Debug Values', values);
                // }}
                subscription={{ submitting: true, pristine: true, values: true, errors: true }}
                render={({ errors, handleSubmit, form, submitting, pristine, values }) => {
                    if (values.isFormSubmit) {
                        onFieldRequiredError(errors, form, values);
                    }

                    return (
                        <form 
                            onSubmit={handleSubmit}
                            id="customFieldGroupForm"
                        >
                            <Dialog 
                                {...props}
                                onClose={handleClose(form, values)}
                            >
                                <DialogTitle className={classes.header}>Custom Field Group Preview</DialogTitle>
                                <DialogContent>{standarFields(form, submitting, pristine, values)}</DialogContent>
                                <DialogActions>{controlButtonComponent(form, submitting, pristine, values)}</DialogActions>
                            </Dialog>
                        </form>
                    );
                }}
            />
        </>
    );
};