import { 
    Badge,
    Chip,
    CircularProgress,
    createStyles,
    IconButton,
    InputAdornment,
    lighten,
    makeStyles, 
    Paper, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    TextField, 
    Theme, 
    Toolbar,
    Tooltip,
    Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
// tslint:disable-next-line: max-line-length
import { 
    DragDropContext, 
    Draggable, 
    // DraggableProvided, 
    // DraggableStateSnapshot, 
    Droppable, 
    DropResult, 
    ResponderProvided 
} from 'react-beautiful-dnd';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import Check from '@material-ui/icons/Check';
import _ from 'lodash';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import { mainTheme } from '../../../Theme';
import { client } from '../../..';
import { showNotification } from '../../../App';
import { useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
            position: 'relative'
        },
        loadingWrapper: {
            position: 'absolute',
            zIndex: 1,
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255,255,255, 0.6)',
        },
        icon: {
            color: mainTheme.BrandColors.TemplatePrimary,
        },
        tableCell: {
            paddingTop: 0,
            paddingBottom: 0,
            '&:hover': {
                '& .drag-icon': {
                    color: mainTheme.BrandColors.TemplatePrimary
                }
            }
        },
        textField: {
            minWidth: 200,
            maxWidth: '100%',
        },
        dragIcon: {
            marginLeft: '-10px',
            marginRight: 10,
            color: '#BDBDBD'
        },
        tableContentWrapper: {
            display: 'flex',
            alignItems: 'center',
        },
        description: {
            cursor: 'pointer'
        }
    })
);

const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
            display: 'flex',
            justifyContent: 'space-between'
        },
        highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
        title: {
            flex: '1 1 100%',
        },
        icon: {
            color: mainTheme.BrandColors.TemplatePrimary,
        },
        rightToolbarWrapper: {
            display: 'flex',
            alignItems: 'center'
        },
        tableTitle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
        tableHeading: {
            color: mainTheme.BrandColors.TemplatePrimary
        },
        chip: {
            marginLeft: '8px'
        },
    })
);

interface EnhancedTableToolbarProps {
    title: string;
    count?: React.ReactNode;
    onClickAdd?: () => void;
    onSearch?: (search: string) => void;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {

    const classes = useToolbarStyles();

    const [search, setSearch] = useState('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        
        setSearch(event.target.value);

        if (props.onSearch) {
            props.onSearch(event.target.value);
        }
    };

    const clearSearch = () => {
        setSearch('');
        if (props.onSearch) {
            props.onSearch('');
        }
    };

    return (
        <Toolbar
            className={classes.root}
        >
            <div className={classes.tableTitle}>
                <Typography className={classes.tableHeading}>{props.title}</Typography>
                <Chip className={classes.chip} label={props.count} />
            </div>
            <div className={classes.rightToolbarWrapper}>
                <TextField
                    // className={classes.margin}
                    placeholder="Search"
                    value={search}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon fontSize="small" className={classes.icon} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={clearSearch}
                                    size="small"
                                >
                                    <CloseIcon fontSize="small" className={classes.icon} />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    onChange={handleChange}
                />
                <Tooltip title="Add">
                    <IconButton 
                        aria-label="filter list"
                        onClick={props.onClickAdd}
                    >
                        <AddBoxRoundedIcon className={classes.icon}/>
                    </IconButton>
                </Tooltip>
            </div>
        </Toolbar>
    );
};

interface CustomFieldLookUpListsTabItem extends CustomFieldLookupList {
    isEdit: boolean;
    isDelete: boolean;
}

interface CustomFieldLookUpListsTabState {
    items: CustomFieldLookUpListsTabItem[];
    isAdd: boolean;
    search: string;
    isDelete: boolean;
    isEdit: boolean;
}

export default function CustomFieldLookUpListsTab() {

    const classes = useStyles();
    
    const history = useHistory();

    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);

    const tab = searchParams.get('tab');

    const { loading, error, data, refetch } = useQuery<CustomFieldLookupListData>(CustomFieldLookupListQuery);

    const [state, setState] = useState<CustomFieldLookUpListsTabState>({
        items: [],
        isAdd: false,
        search: '',
        isEdit: false,
        isDelete: false,
    });

    const textFieldPlaceHolder = 'Description';

    useEffect(() => {
        if (!error && data && data.crm && data.crm.customFieldLookupList ) {
            setState((prevState) => {
                return {
                    ...prevState,
                    items: data.crm.customFieldLookupList.map((value) => {
                        return { 
                            guidId: value.guidId,
                            description: value.description,
                            sequence: value.sequence,
                            customFieldLookupListItemCount: value.customFieldLookupListItemCount,
                            isDelete: false,
                            isEdit: false
                        };
                    }).sort((a, b) => a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0)
                };
            });
        }
    // tslint:disable-next-line: align
    }, [data, error, loading]);

    useEffect(() => {
        
        const subscribeFirmObj = subscribeFirm();

        return () => {
            subscribeFirmObj.unsubscribe();
        };
    // tslint:disable-next-line: align
    }, []);

    const subscribeFirm = () => {
        return (
            // let subscription =
            client
                .subscribe<CustomFieldLookupNotificationSubscriptionData>({
                    fetchPolicy: 'no-cache',
                    query: CustomFieldLookupNotificationSubscription,
                    variables: {},
                })
                .subscribe({
                    // tslint:disable-next-line:no-any
                    next(results) {
                        if (results.data?.lookupNotification.status) {

                            const { lookupNotification } = results.data;

                            showNotification('Success', lookupNotification.message, 'info');

                            refetch();

                        } else {
                            showNotification('Failed', results.data?.lookupNotification.message || '', 'error');
                        }
                    },
                    // tslint:disable-next-line:no-any
                    error(err: any) {
                        // tslint:disable-next-line:no-console
                        console.error(err);
                        showNotification(null, err, 'error');
                    },
                })
        );
    };
    
    let textField = '';

    // itemInputRef.current = getItems(10).map((ref, index) => itemInputRef.current[index] = React.createRef());

    const reorder = (list: CustomFieldLookUpListsTabItem[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
    };

    const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
    
        const items = reorder(
            state.items,
            result.source.index,
            result.destination.index
        ).map((item: CustomFieldLookUpListsTabItem, index: number) => {
            return {
                ...item,
                sequence: index
            };
        });

        setState((prevState) => {
            
            client
            .mutate({
                mutation: UpdateCustomFieldSequenceLookupListMutation,
                variables: {
                    lookupSequences: items.map((item: CustomFieldLookUpListsTabItem) => {
                        return {
                            guidId: item.guidId, 
                            sequence: item.sequence
                        };
                    })
                },
            })
            // tslint:disable-next-line: no-any
            .then((results: { data: any }) => {
                if (results.data) {
                    if (results.data.error !== undefined) {
                        showNotification( 'Failed to update Task Type Group sorting', results.data.error, 'error');
                    }
                }
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to update Task Type Group sorting', reason, 'error');
            });

            return {
                ...prevState,
                items
            };
        });

    };

    // const getListStyle = (isDraggingOver: boolean) => ({
    //     background: isDraggingOver ? 'lightblue' : 'lightgrey',
    //     padding: grid,
    //     width: 250
    // });

    // tslint:disable-next-line: no-any
    const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
        // styles we need to apply on draggables
        ...draggableStyle,

        ...(isDragging && {
            background: 'rgb(235,235,235)'
        }),
        position: 'static',
        zIndex: 1
    });

    const onClickAdd = () => {
        setState((prevState) => {
            return {
                ...prevState,
                isAdd: true
            };
        });
    };

    const onClickCancel = () => {
        setState((prevState) => {
            return {
                ...prevState,
                isAdd: false,
                isEdit: false,
                isDelete: false,
            };
        });
    };

    const onSearch = (search: string) => {
        setState((prevState) => {
            return {
                ...prevState,
                search
            };
        });
    };

    const onEditRowEnable = (item: CustomFieldLookUpListsTabItem) => () => {
        const items = [...state.items];
        
        const index = items.indexOf(item);

        items[index].isEdit = true;

        setState((prevState) => {
            return {
                ...prevState,
                items,
                isEdit: true,
            };
        });
    };

    const onCancelEditRow = (item: CustomFieldLookUpListsTabItem) => () => {
        // code here..
        const items = [...state.items];
        
        const index = items.indexOf(item);

        items[index].isEdit = false;

        setState((prevState) => {
            return {
                ...prevState,
                items,
                isEdit: false,
            };
        });
    };

    const onEditRow = (item: CustomFieldLookUpListsTabItem, index: number) => () => {

        if (textField) {

            setState((prevState) => {
                client
                .mutate({
                    mutation: UpdateCustomFieldLookupListMutation,
                    variables: {
                        guidId: item.guidId,
                        description: textField,
                    },
                })
                // tslint:disable-next-line: no-any
                .then((results: { data: any }) => {
                    if (results.data) {
                        if (results.data.error !== undefined) {
                            showNotification( 'Failed to Update Task Type Group', results.data.error, 'error');
                        }
                    }
                })
                // tslint:disable-next-line:no-any
                .catch((reason: any) => {
                    showNotification('Failed to Update Task Type Group', reason, 'error');
                });

                const tdata = [...prevState.items];
                tdata[tdata.indexOf(item)].isEdit = false;
                tdata[tdata.indexOf(item)].description = textField;
                
                return { 
                    ...prevState, 
                    data: tdata,
                    isEdit: false
                };
            });
        }
    };

    const onDeleteRowEnable = (item: CustomFieldLookUpListsTabItem) => () => {

        setState((prevState) => {

            const items = [...prevState.items];
        
            const index = items.indexOf(item);

            items[index].isDelete = true;

            return {
                ...prevState,
                items,
                isDelete: true
            };
        });
    };

    const onCancelDeleteRow = (item: CustomFieldLookUpListsTabItem) => () => {
        // code here..

        setState((prevState) => {

            const items = [...prevState.items];
        
            const index = items.indexOf(item);

            items[index].isDelete = false;

            return {
                ...prevState,
                items,
                isDelete: false
            };
        });
    };

    const onDeleteRow = (item: CustomFieldLookUpListsTabItem) => () => {
        
        setState((prevState) => {

            client
            .mutate({
                mutation: DeleteCustomFieldLookupListMutation,
                variables: {
                    guidId: item.guidId,
                    description: item.description
                },
            })
            // tslint:disable-next-line: no-any
            .then((results: { data: any }) => {
                if (results.data) {
                    if (results.data.error !== undefined) {
                        showNotification( 'Failed to Delete Task Type Group', results.data.error, 'error');
                    }
                }
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to Delete Task Type Group', reason, 'error');
            });

            const tItems = [...prevState.items];
            tItems.splice(tItems.indexOf(item), 1);

            return {
                ...prevState,
                isDelete: false,
                items: tItems
            };
        });
    };

    const onAddItem = () => {
        
        if (textField) {

            client
            .mutate<CreateCustomFieldLookupListMutationData, CreateCustomFieldLookupListMutationParams>({
                mutation: CreateCustomFieldLookupListMutation,
                variables: {
                    description: textField,
                    sequence: state.items.length + 1,
                },
            })
            // tslint:disable-next-line: no-any
            .then((results) => {
                if (results.data) {
                    if (!results.data.createCustomFieldLookupList) {
                        showNotification(null, 'Failed to create custom field', 'error');
                    } else {
                        showNotification(null, 'Create custom field submitted', 'info');
                        setState((prevState) => {

                            // const tItems = [...prevState.items];
                            
                            // tItems.push({
                            //     guidId: '',
                            //     description: textField,
                            //     sequence: prevState.items.length,
                            //     customFieldLookupListItemCount: 0,
                            //     isEdit: false,
                            //     isDelete: false,
                            // });
                            
                            return {
                                ...prevState,
                                isAdd: false,
                                // items: tItems
                            };
                        });
                    }
                    
                }
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to create Task Type Group', reason, 'error');
            });
        }
    
    };

    const onClickTagCounts = (item: CustomFieldLookUpListsTabItem) => () => {
        
        history.push({
            pathname: 'enquiryCustomFieldsConfiguration',
            search: `?tab=${tab}&tabKey=${item.guidId}&title=${item.description}`,
        });

    };

    const onChangeUpdate = (item: CustomFieldLookUpListsTabItem, index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        textField = event.target.value;
    };

    const onChangeAdd = (event: React.ChangeEvent<HTMLInputElement>) => {
        textField = event.target.value;
    };

    // tslint:disable-next-line: no-any
    const DroppableComponent = (onDragEndComponent: (result: DropResult, provided: ResponderProvided) => void) => (props: any) =>  {
        return (
            <DragDropContext onDragEnd={onDragEndComponent}>
                <Droppable droppableId={'1'} direction="vertical">
                    {(provided) => {
                        return (
                            <TableBody ref={provided.innerRef} {...provided.droppableProps} {...props}>
                                {props.children}
                                {provided.placeholder}
                            </TableBody>
                        );
                    }}
                </Droppable>
            </DragDropContext>
        );
    };

    // tslint:disable-next-line: no-any
    const DraggableComponent = (item: CustomFieldLookUpListsTabItem, index: number) => (props: any) => {
        return (
            <Draggable draggableId={item.guidId} index={index}>
                {(provided, snapshot) => (
                    <TableRow
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
    
                        {...props}
                    >
                        {!!(!item.isDelete && !item.isEdit)  && (
                             <>
                                <TableCell className={classes.tableCell}>
                                    <div className={classes.tableContentWrapper}>
                                        <DragIndicatorIcon fontSize="small" className={`${classes.dragIcon} drag-icon`} />
                                        <div
                                            onClick={onClickTagCounts(item)}
                                            className={classes.description}
                                        >
                                            {item.description}
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell className={classes.tableCell} align="center">
                                    <IconButton
                                        onClick={onClickTagCounts(item)}
                                    >
                                        <Badge color="primary" badgeContent={item.customFieldLookupListItemCount || undefined}>
                                            <LocalOfferOutlinedIcon color="primary" />
                                        </Badge>
                                    </IconButton>
                                </TableCell>
                                <TableCell className={classes.tableCell} align="right">
                                    <IconButton 
                                        aria-label="Edit"
                                        disabled={state.isAdd || state.isEdit || state.isDelete}
                                        color="primary"
                                        onClick={onEditRowEnable(item)}
                                    >
                                        <Edit />
                                    </IconButton>
                                    <IconButton 
                                        aria-label="Delete"
                                        disabled={state.isAdd || state.isEdit || state.isDelete}
                                        color="primary"
                                        onClick={onDeleteRowEnable(item)}
                                    >
                                        <DeleteOutline />
                                    </IconButton>
                                </TableCell>
                            </>
                        )}

                        {!!(item.isDelete && state.isDelete) && (
                            <>
                                <TableCell className={classes.tableCell} colSpan={2}>
                                    Are you sure you want to delete this row?
                                </TableCell>
                                <TableCell className={classes.tableCell} align="right">
                                    <IconButton 
                                        aria-label="ok"
                                        // disabled={state.isAdd}
                                        color="primary"
                                        onClick={onDeleteRow(item)}
                                    >
                                        <Check />
                                    </IconButton>
                                    <IconButton 
                                        aria-label="cancel"
                                        // disabled={state.isAdd}
                                        color="primary"
                                        onClick={onCancelDeleteRow(item)}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </TableCell>
                            </>
                        )}
                        {!!(item.isEdit && state.isEdit) && (
                            <>
                                <TableCell className={classes.tableCell} colSpan={2}>
                                    <TextField 
                                        placeholder={textFieldPlaceHolder}
                                        defaultValue={item.description}
                                        onChange={onChangeUpdate(item, index)}
                                        className={classes.textField}
                                    />
                                </TableCell>
                                <TableCell className={classes.tableCell} align="right">
                                    <IconButton 
                                        aria-label="ok"
                                        disabled={state.isAdd}
                                        color="primary"
                                        onClick={onEditRow(item, index)}
                                    >
                                        <Check />
                                    </IconButton>
                                    <IconButton 
                                        aria-label="cancel"
                                        disabled={state.isAdd}
                                        color="primary"
                                        onClick={onCancelEditRow(item)}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </TableCell>
                            </>
                        )}
                        
                    </TableRow>
                )}
            </Draggable>
        );
    };

    const getDraggableRows = () => {
        let filteredItems = state.items;
        
        if (state.search) {
            filteredItems = state.items.filter((item: CustomFieldLookUpListsTabItem) => {
                return item.description.toLowerCase().indexOf(state.search) >= 0;
            });
        }       

        return (
            _.map(filteredItems, (item, index) => {
                return (
                    <TableRow 
                        component={DraggableComponent(item, index)} 
                        key={item.guidId}
                    />
                );
            })
        );
    };

    return (
        <div className={`${classes.root}`}>
            <Paper className={classes.paper}>
                {!!loading && (
                    <div className={classes.loadingWrapper}>
                        <CircularProgress />
                    </div>
                )}
                <EnhancedTableToolbar 
                    title="Custom Field Lookup Lists"
                    count={state.items.length}
                    onClickAdd={onClickAdd}
                    onSearch={onSearch}
                />
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Description</TableCell>
                                <TableCell align="center">Count</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody component={DroppableComponent(onDragEnd)}>
                            {!!state.isAdd && (
                                <TableRow>
                                    <TableCell className={classes.tableCell} colSpan={2}>
                                        <TextField
                                            placeholder={textFieldPlaceHolder}
                                            className={classes.textField}
                                            onChange={onChangeAdd}
                                        />
                                    </TableCell>
                                    <TableCell className={classes.tableCell} align="right">
                                        <Tooltip title="Save">
                                            <IconButton 
                                                aria-label="Save"
                                                onClick={onAddItem}
                                            >
                                                <Check className={classes.icon}/>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Cancel">
                                            <IconButton 
                                                aria-label="Delete"
                                                onClick={onClickCancel}
                                            >
                                                <CloseIcon className={classes.icon}/>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            )}
                            {
                                getDraggableRows()
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
}

interface CustomFieldLookupListData {
    loading?: boolean;
    networkStatus?: number;
    crm: CRM;
};

type CRM = {
    customFieldLookupList: CustomFieldLookupList[];
};

type CustomFieldLookupList = {
    guidId:                         string;
    description:                    string;
    customFieldLookupListItemCount: number;
    sequence:                       number;
};


const CustomFieldLookupListQuery = gql`
    query CustomFieldLookupList {
        crm {
            customFieldLookupList {
                guidId
                description
                customFieldLookupListItemCount
                sequence
            }
        }
    }
`;

type CustomFieldLookupNotificationSubscriptionData = {
    lookupNotification: LookupNotification;
};

type LookupNotification = {
    id:                number;
    guidId:            null;
    lookupDescription: null;
    message:           string;
    status:            boolean;
};


const CustomFieldLookupNotificationSubscription = gql`
    subscription customFieldLookupNotification {
        lookupNotification{
            id,
            guidId,
            lookupDescription,
            message,
            status
        }
    }
`;

type CreateCustomFieldLookupListMutationParams = {
    description: string;
    sequence: number;
};

type CreateCustomFieldLookupListMutationData = {
    createCustomFieldLookupList: boolean;
};

const CreateCustomFieldLookupListMutation = gql`
    mutation CreateCustomFieldLookupList($description: String, $sequence: Int) {
        createCustomFieldLookupList(description: $description, sequence: $sequence)
    }
`;

const UpdateCustomFieldLookupListMutation = gql`
    mutation UpdateCustomFieldLookupList($description: String, $guidId: String) {
        updateCustomFieldLookupList(description: $description, guidId: $guidId)
    }
`;

const DeleteCustomFieldLookupListMutation = gql`
    mutation DeleteCustomFieldLookupList($description: String, $guidId: String) {
        deleteCustomFieldLookupList(description: $description, guidId: $guidId)
    }
`;

const UpdateCustomFieldSequenceLookupListMutation = gql`
    mutation UpdateCustomFieldSequenceLookupList($lookupSequences: [LookupSequence]) {
        updateCustomFieldLookupListSequence(lookupSequences: $lookupSequences)
    }
`;