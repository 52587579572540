import React from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, Checkbox, FormControlLabel, Grid, TextField, Theme, createStyles, makeStyles } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { FormApi, ValidationErrors } from 'final-form';
import { checkIfIntialValuesIsEqualToValues } from '../../helpers/commonHelper';
import DialogBox from '../../Dashboard/Component/DialogBox';
import { AreaOfLawSelector, IAreaOfLawAutoCompleteItem } from '../../enquirySummary/selectors/AreaOfLawSelector';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import gql from 'graphql-tag';
import { client } from '../..';
import { showNotification } from '../../App';
import { CustomFieldGroup } from '../tabs/customFieldGroups/CustomFieldGroupsTab';
import { OfficeSelector } from '../../MailingList/selectors/OfficeSelector';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            textTransform: 'capitalize',
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        button: {
            margin: theme.spacing(1),
            minWidth: '83px',
        },
        textField: {
            width: '100%',
        },
    }),
);

interface CustomFieldGroupDialogProps extends Pick<DialogProps, 'open' | 'onClose' | 'maxWidth' | 'fullWidth'> {
    data?: CustomFieldGroup;
}

interface CustomFieldForm {
    isFormSubmit: boolean;
    name: string | null;
    groupId: string | null;
    description: string | null;
    areaOfLaw: IAreaOfLawAutoCompleteItem | null;
    location: IAutoCompleteItem | null;
    isActive: boolean;
}

export const CustomFieldGroupDialog: React.FC<CustomFieldGroupDialogProps> = (props) => {
    const classes = useStyles();

    const [showDiscard, setShowDiscard] = React.useState<boolean>(false);
    
    const initialValues: CustomFieldForm = {
        isFormSubmit: false,
        name: props.data ? props.data.name : null,
        areaOfLaw: props.data && props.data.areaOfLaw && props.data.areaOfLawGuid ? { label: props.data.areaOfLaw, value: props.data.areaOfLawGuid } as any : null,
        description: props.data ? props.data.description : null,
        groupId: props.data ? props.data.groupId : null,
        isActive: props.data ? props.data.isActive : true,
        location: props.data && props.data.location && props.data.locationGuid ? { label: props.data.location, value: props.data.locationGuid } : null,
    };

    const handleClose = (form: FormApi<CustomFieldForm>, values: CustomFieldForm) => () => {

        const areValuesEqual = checkIfIntialValuesIsEqualToValues(initialValues, values);

        if (!areValuesEqual) {
            setShowDiscard(true);
        } else {
            if (props.onClose) {
                props.onClose({}, 'backdropClick');
            }
        }
    };

    const onDiscardChanges = (agree: boolean) => {
        if (agree) {
            if (props.onClose) {
                props.onClose({}, 'backdropClick');
            }
        }
        setShowDiscard(false);
    };

    const onSubmit = (values: CustomFieldForm, form: FormApi<CustomFieldForm, CustomFieldForm>) => {
        // tslint:disable-next-line: no-console
        console.log('onSubmit', values);

        if (!props.data) {
            client.mutate<CreateEnquiryCustomFieldGroupMutationData, CreateEnquiryCustomFieldGroupMutationParams>({
                mutation: CreateEnquiryCustomFieldGroupMutation,
                variables: {
                    areaOfLawGuid: values.areaOfLaw ? values.areaOfLaw.value : null,
                    description: values.description,
                    groupId: values.groupId || '',
                    groupName: values.name || '',
                    isActive: values.isActive,
                    locationGuid: values.location ? values.location.value : null,
                }
            })
            .then((results) => { 
                if (results.data) {
                    if (results.data.createEnquiryCustomFieldGroup.status && results.data.createEnquiryCustomFieldGroup.error === null) {
                        showNotification(null, 'Create custom field submitted', 'info');  
                        if (props.onClose) {
                            props.onClose({}, 'backdropClick');
                        }
                    } else {
                        showNotification(null, 'Create custom field failed', 'error');
                    }
                }            
            }) // tslint:disable-next-line:no-any
            .catch((reason) => { 
                showNotification(null, reason, 'error'); 
            });
        } else {

            console.log('values', values);

            client.mutate<UpdateEnquiryCustomFieldGroupMutationData, UpdateEnquiryCustomFieldGroupMutationParams>({
                mutation: UpdateEnquiryCustomFieldGroupMutation,
                variables: {
                    guidId: props.data.guid,
                    areaOfLawGuid: values.areaOfLaw ? values.areaOfLaw.value : null,
                    description: values.description,
                    groupId: values.groupId || '',
                    groupName: values.name || '',
                    isActive: values.isActive,
                    locationGuid: values.location ? values.location.value : null,
                }
            })
            .then((results) => { 
                if (results.data) {
                    if (results.data.updateEnquiryCustomFieldGroup.status && results.data.updateEnquiryCustomFieldGroup.error === null) {
                        showNotification(null, 'Create custom field submitted', 'info');  
                        if (props.onClose) {
                            props.onClose({}, 'backdropClick');
                        }
                    } else {
                        showNotification(null, 'Create custom field failed', 'error');
                    }
                }            
            }) // tslint:disable-next-line:no-any
            .catch((reason) => { 
                showNotification(null, reason, 'error'); 
            });
        }
    };

    const onValdiate = (values: CustomFieldForm) => {
        return undefined;
    };

    const onRequired = (value: CustomFieldForm) => (value ? undefined : 'Required');

    const validateGroupId = (value: string) => {
        if (!value) {
            return 'Required';
        }
        if (value.length !== 3) {
            return 'Group ID must be at least 3 characters';
        }

        return undefined;
    };

    const onFieldRequiredError = (errors: ValidationErrors, form: FormApi<CustomFieldForm>, values: CustomFieldForm) => {
        // code here
        console.log('errors', errors);
    };
    
    const controlButtonComponent = (form: FormApi<CustomFieldForm>, submitting: boolean, pristine: boolean, values: CustomFieldForm) => {
        return (
            <div className={classes.buttonContainer}>
                {/* <RenderCount /> */}
                <div>
                    <Button
                        color="primary"
                        className={classes.button}
                        disabled={submitting || pristine}
                        onClick={() => {
                            form.change('isFormSubmit', true);
                            form.submit();
                        }}
                    >
                        Save
                    </Button>
                </div>
                <div>
                    <Button color="primary" type="button" className={classes.button} onClick={handleClose(form, values)}>
                        Cancel
                    </Button>
                </div>
            </div>
        );
    };
    
    const standarFields = (form: FormApi<CustomFieldForm>, submitting: boolean, pristine: boolean, values: CustomFieldForm) => {
        return (
            <Grid container={true} spacing={1}>
                <Grid item={true} xs={12} sm={12} md={6} lg={6}>
                    <Field name={`groupId`} subscription={{ touched: true, error: true, value: true }} validate={validateGroupId}>
                        {({ input, meta }) => (
                            <TextField
                                {...input}
                                className={classes.textField}
                                error={meta.error && meta.touched}
                                label="Group ID"
                                helperText={meta.error && meta.touched ? meta.error : ''}
                                required={true}
                            />
                        )}
                    </Field>
                </Grid>
                <Grid item={true} xs={12} sm={12} md={6} lg={6}>
                    <Field 
                        name={`name`} subscription={{ touched: true, error: true, value: true }} validate={onRequired}>
                        {({ input, meta }) => (
                            <TextField
                                {...input}
                                className={classes.textField}
                                error={meta.error && meta.touched}
                                label="Group Name"
                                helperText={meta.error && meta.touched ? meta.error : ''}
                                required={true}
                            />
                        )}
                    </Field>
                </Grid>
                <Grid item={true} xs={12} sm={12} md={6} lg={6}>
                    <Field name={`description`} subscription={{ touched: true, error: true, value: true }}>
                        {({ input, meta }) => (
                            <TextField
                                {...input}
                                className={classes.textField}
                                error={meta.error && meta.touched}
                                label="Description"
                                helperText={meta.error && meta.touched ? meta.error : ''}
                            />
                        )}
                    </Field>
                </Grid>
                <Grid item={true} xs={12} sm={12} md={6} lg={6}>
                    <Field name={`areaOfLaw`} subscription={{ touched: true, error: true, value: true }}>
                        {({ input, meta }) => (
                            <AreaOfLawSelector
                                {...input}
                                label={'Area of Law'}
                                className={classes.textField}
                                error={meta.error && meta.touched}
                                onSelection={(selection: IAreaOfLawAutoCompleteItem) => {
                                    input.onChange(selection);
                                }}
                                onBlur={input.onBlur}
                            />
                        )}
                    </Field>
                </Grid>
                <Grid item={true} xs={12} sm={12} md={6} lg={6}>
                    <Field name={`location`} subscription={{ touched: true, error: true, value: true }}>
                        {({ input, meta }) => (
                            <OfficeSelector
                                {...input}
                                label="Location"
                                name="office"
                                value={input.value ? input.value : null}
                                className={classes.textField}
                                error={meta.error && meta.touched}
                                onSelection={(selection: IAutoCompleteItem, name: string) =>
                                    input.onChange(selection)
                                }
                                helperText={meta.error && meta.touched
                                    ? 'Location is required'
                                    : ''
                                }
                            />
                        )}
                    </Field>
                </Grid>
                <Grid item={true} xs={12} sm={12} md={6} lg={6}>
                    <Field
                        name="isActive"
                        subscription={{touched: true, error: true, value: true}}
                    >                                            
                        {({ input, meta }) => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        {...input}
                                        checked={input.value}
                                        onChange={input.onChange}
                                        checkedIcon={<CheckBoxIcon color="primary" />}
                                    />
                                }
                                label="Active"
                            />
                        )}
                    </Field>
                </Grid>
            </Grid>
        );
    };
    
    return (
        <>
            <DialogBox
                title="Custom Field Group"
                // tslint:disable-next-line:max-line-length
                content={`Are you sure you want to close the form?`}
                show={showDiscard}
                isAgree={onDiscardChanges}
                disAgreeLabel={'No'}
                agreeLabel={'Yes'}
            />

            <Form
                onSubmit={onSubmit}
                // mutators={{ ...arrayMutators }}
                initialValues={initialValues}
                validate={onValdiate}
                keepDirtyOnReinitialize={true}
                initialValuesEqual={() => true}
                // debug={(values) => {
                //     // tslint:disable-next-line: no-console
                //     console.log('Debug Values', values);
                // }}
                subscription={{ submitting: true, pristine: true, values: true, errors: true }}
                render={({ errors, handleSubmit, form, submitting, pristine, values }) => {
                    if (values.isFormSubmit) {
                        onFieldRequiredError(errors, form, values);
                    }

                    return (
                        <form 
                            onSubmit={handleSubmit}
                            id="customFieldGroupForm"
                        >
                            <Dialog 
                                {...props}
                                onClose={handleClose(form, values)}
                            >
                                <DialogTitle className={classes.header}>Custom Field Group</DialogTitle>
                                <DialogContent>{standarFields(form, submitting, pristine, values)}</DialogContent>
                                <DialogActions>{controlButtonComponent(form, submitting, pristine, values)}</DialogActions>
                            </Dialog>
                        </form>
                    );
                }}
            />
        </>
    );
};



type CreateEnquiryCustomFieldGroupMutationParams = {
    groupId: string;
    groupName: string;
    description: string | null;
    areaOfLawGuid: string | null;
    locationGuid: string | null;
    isActive: boolean | null;
};

type CreateEnquiryCustomFieldGroupMutationData = {
    createEnquiryCustomFieldGroup: CreateEnquiryCustomFieldGroup;
};

type CreateEnquiryCustomFieldGroup = {
    error:  null;
    status: boolean;
};

const CreateEnquiryCustomFieldGroupMutation = gql`
    mutation CreateEnquiryCustomFieldGroup (
        $groupId: String,
        $groupName: String,
        $description: String,
        $areaOfLawGuid: String,
        $locationGuid: String,
        $isActive: Boolean
    ) {
        createEnquiryCustomFieldGroup(
            groupId: $groupId,
            groupName: $groupName,
            description: $description,
            areaOfLawGuid: $areaOfLawGuid,
            locationGuid: $locationGuid,
            isActive: $isActive
        ) {
            error
            status
        }
    }
`;

type UpdateEnquiryCustomFieldGroupMutationParams = {
    guidId: string;
    groupId: string;
    groupName: string;
    description: string | null;
    areaOfLawGuid: string | null;
    locationGuid: string | null;
    isActive: boolean | null;
};

type UpdateEnquiryCustomFieldGroupMutationData = {
    updateEnquiryCustomFieldGroup: UpdateEnquiryCustomFieldGroup;
};

type UpdateEnquiryCustomFieldGroup = {
    error:  null;
    status: boolean;
};

const UpdateEnquiryCustomFieldGroupMutation = gql`
    mutation UpdateEnquiryCustomFieldGroup  (
        $guidId: String,
        $groupId: String,
        $groupName: String,
        $description: String,
        $areaOfLawGuid: String,
        $locationGuid: String,
        $isActive: Boolean
    ) {
        updateEnquiryCustomFieldGroup(
        guidId: $guidId,
        groupId: $groupId,
        groupName: $groupName,
        description: $description,
        areaOfLawGuid: $areaOfLawGuid,
        locationGuid: $locationGuid,
        isActive: $isActive
        ) {
            error
            status
        }
    }
`;